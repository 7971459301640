import React from 'react';
import { Typography } from "antd";

const { Title } = Typography;

const NotFound = () => {
  return (
    <>
      <Title>Not Found</Title>
    </>
  );
};

export default NotFound;
